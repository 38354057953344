import { createAsyncThunk } from "@reduxjs/toolkit";
import agroAxiosService from "../../services/agroAxiosService";
import paymentAxiosService from "../../services/paymentAxiosService";
import { errorHandler } from "../../utils/actionsErrorHandler";
import { createQuery } from "../../utils/createQuery";

export const getBanks = createAsyncThunk(
  "utility/getBanks",
  async (thunkAPI) => {
    try {
      const response = await paymentAxiosService.get(`/banks`);
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

// : { bankCode: string; accountNumber: string }
export const getBankAccountName = createAsyncThunk(
  "utility/getBankAccountName",
  async (payload, thunkAPI) => {
    try {
      const response = await paymentAxiosService.post(
        `/disbursement/validate-account-number`,
        payload
      );
      // console.log(`Bank Details fetched`);
      return response?.data;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const getStates = createAsyncThunk(
  "utility/getStates",
  async (queryParams = {}, thunkAPI) => {
    // console.log(localStorage.getItem('states'));
    if (!sessionStorage.getItem("states")) {
      try {
        const response = await agroAxiosService.get(
          `/v3/all/states${createQuery(queryParams)}`
        );
        // console.log(response && response.success);
        const objectifiedStates = {};

        if (response?.result?.states) {
          response.result.states.forEach((state) => {
            objectifiedStates[state.state_id] = state;
          });
        }

        // dispatch({
        //   type: SET_STATES,
        //   payload: {
        //     states: response.result?.states,
        //     localStates: objectifiedStates,
        //   },
        // });

        return {
          states: response?.result?.states,
          localStates: objectifiedStates,
        };
      } catch (error) {
        console.log(error);
        errorHandler(error);
        throw error;
      }
    }
    // else {
    //   dispatch({
    //     type: GET_STATES,
    //   });
    // }
  }
);

export const getLocalGovt = createAsyncThunk(
  "utility/getLocalGovt",
  async (stateId, thunkAPI) => {
    // console.log(localStorage.getItem('states'));
    try {
      const response = await agroAxiosService.get(
        `/v3/all/states/${stateId}/locals`
      );
      return {
        localGovt: response?.result?.locals,
      };
    } catch (error) {
      console.log(error);
      errorHandler(error);
      throw error;
    }
  }
);
