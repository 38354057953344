import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// import authAxiosService from "../../services/authAxiosService";
import axiosServices from "../../services/axiosServices";
import { createQuery } from "../../utils/createQuery";

export const getAllCashAdvance = createAsyncThunk(
  "auth/getAllCashAdvance",
  async (queryParams = { page: 1 }, thunkAPI) => {
    try {
      const queryParamString = createQuery(queryParams);
      const res = await axiosServices.get(
        `/cash-advance/all${queryParamString}`
      );
      // console.log("getAllCashAdvance", res);
      const data = res?.result;
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);

export const addUser = createAsyncThunk(
  "auth/addUser",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServices.post(`/users`, payload);
      // console.log(response);
      toast.success("User added successfully");
      window.location.href = "/crop-gate/user-management/users";
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);

export const getUserById = createAsyncThunk(
  "auth/getUserById",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServices.get(`/users/${payload}`);
      // console.log(response);
      return response?.result?.user;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);

export const disburseCashAdvance = createAsyncThunk(
  "auth/disburseCashAdvance",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServices.post(
        `/cash-advance/disburse`,
        payload
      );
      // console.log(response);
      toast.success(response?.data?.message);
      window.location.href = "/crop-gate/cash-advance";
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);
