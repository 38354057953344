// import { getAllLoanHistory } from './../actions/dashboard.action';
import { createSlice } from "@reduxjs/toolkit";
// import { notify } from '../../utils/toastNotification';

const initialState = {
  client: {
    isLoading: false
  },
  admin: {
    isLoading: false
  }
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: {
  }
});

export default dashboardSlice;