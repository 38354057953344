import React, { useState, Fragment } from "react";

import {
  Collapse,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const CustomListItem = (props) => {
  const { selected, onClick, href, icon, children, text = "", ...rest } = props;
  const [open, setOpen] = useState(false);

  const handleClick = (propOnClick) => {
    return (ev) => {
      if (propOnClick) {
        propOnClick(ev);
      }
      // console.log(ev);
      setOpen(!open);
    };
  };

  const iconOnClick = (ev) => {
    ev.stopPropagation();
  };

  const moreIcon = open ? <ExpandLess /> : <ExpandMore />;

  return (
    <Fragment>
      <ListItemButton
        selected={!!selected}
        onClick={handleClick(onClick)}
        href={href || null}
        {...rest}
      >
        {icon !== false && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText className="list-item-text" primary={text} />
        {!children ? null : moreIcon}
      </ListItemButton>

      {!children ? null : (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </Fragment>
  );
};

export default CustomListItem;
