import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "../../services/axiosServices";
import agroAxiosService, { useAxiosService as useAgroAxiosService } from "../../services/agroAxiosService";
// import adminAxiosService from "../../services/adminAxiosService";
import { createQuery } from "../../utils/createQuery";
import { errorHandler } from "../../utils/actionsErrorHandler";
import { convertToFormData } from "../../utils/convertJSONToFormData";
import { CROP_GATE_ACCESS_KEY } from "../../configs/api";
import { notify } from "../../utils/toastNotification";

export const getFarmers = createAsyncThunk(
  "supplier/getFarmers",
  async (
    queryParams = {
      page: 1,
      limit: 20,
    },
    thunkAPI
  ) => {
    try {
      const queryParamString = createQuery(queryParams);

      const response = await axiosService.get(`/farmers${queryParamString}`);
      // console.log({ response });
      return response?.result;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const addFarmer = createAsyncThunk(
  "supplier/addFarmer",
  async (payload, thunkAPI) => {
    try {
      const response = await agroAxiosService.post(`/v3/farmers`, payload);
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const getSingleFarmer = createAsyncThunk(
  "supplier/getSingleFarmer",
  async (farmerId, thunkAPI) => {
    try {
      const response = await axiosService.get(`/farmers/${farmerId}`);
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const updateFarmer = createAsyncThunk(
  "supplier/updateFarmer",
  async ({ farmerId, ...payload }, thunkAPI) => {
    try {
      const response = await axiosService.patch(
        `/farmers/${farmerId}`,
        payload
      );
      // console.log(response);
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const uploadFarmers = createAsyncThunk(
  "supplier/uploadFarmers",
  async (payload, thunkAPI) => {
    try {
      payload = convertToFormData(payload);
      const response = await useAgroAxiosService("formData")({
        method: "POST",
        url: `/crop-gate/farmers/bulk-upload`,
        data: payload,
        headers: {
          "crop-gate-access-key": CROP_GATE_ACCESS_KEY,
        },
      });

      console.log(response);
      if (response?.success && response?.result) {
        notify("Farmers uploaded successfully", { type: "success" });
      }
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const downloadFarmerTemplate = async () => {
  try {
    agroAxiosService({
      url: "/v3/templates/farmers",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      // console.log([response.data]);
      const url = window.URL.createObjectURL(
        new Blob([response], {
          encoding: "UTF-8",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "FARMER UPLOAD SAMPLE.xlsx"); //or any other extension
      link.click();
      window.URL.revokeObjectURL(url);
    });
    // window.open(`${BASE_URL}/templates/farmers`, "_blank");
  } catch (error) {
    console.log(error);
    errorHandler(error);
    return error;
  }
};

export const getSuppliers = createAsyncThunk(
  "supplier/getSuppliers",
  async (
    queryParams = {
      page: 1,
      limit: 20,
    },
    thunkAPI
  ) => {
    try {
      const queryParamString = createQuery(queryParams);

      const response = await axiosService.get(`/suppliers${queryParamString}`);
      // console.log({ response });
      return response?.result;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const getSingleSupplier = createAsyncThunk(
  "supplier/getSingleSupplier",
  async (supplierId, thunkAPI) => {
    try {
      const response = await axiosService.get(`/suppliers/${supplierId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const addSupplier = createAsyncThunk(
  "supplier/addSupplier",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosService.post(`/suppliers`, payload);
      // console.log(response);
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const updateSupplier = createAsyncThunk(
  "supplier/updateSupplier",
  async ({ supplierId, ...payload }, thunkAPI) => {
    try {
      const response = await axiosService.patch(
        `/suppliers/${supplierId}`,
        payload
      );
      // console.log(response);
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const getCommodities = createAsyncThunk(
  "supplier/getCommodities",
  async ({ supplierId, ...queryParams }, thunkAPI) => {
    try {
      const queryParamString = createQuery(queryParams);

      const response = await axiosService.get(
        `/supplier/${supplierId}/commodities${queryParamString}`
      );
      // console.log({ response });
      return response?.result;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const getSingleCommodity = createAsyncThunk(
  "supplier/getSingleCommodity",
  async (supplierId, thunkAPI) => {
    try {
      const response = await axiosService.get(`/suppliers/${supplierId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const addCommodity = createAsyncThunk(
  "supplier/addCommodity",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosService.post(
        `/supplier/commodities`,
        payload
      );
      // console.log(response);
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const updateCommodity = createAsyncThunk(
  "supplier/updateCommodity",
  async ({ commodityId, ...payload }, thunkAPI) => {
    try {
      const response = await axiosService.patch(
        `/suppliers/${commodityId}`,
        payload
      );
      // console.log(response);
      return response;
    } catch (error) {
      // errorHandler(error, "An error occurred");
      throw error;
    }
  }
);
