import { getBanks, getLocalGovt, getStates } from "../actions/utility.action";
import { createSlice } from "@reduxjs/toolkit";
// import { notify } from "../../utils/toastNotification";

const initialState = {
  banks: [],
  isLoading: false,
  loading: false,
  states: {},
  localGovt: [],
};

const utilitySlice = createSlice({
  name: "utility",
  initialState,
  reducers: {
    setStates(state, action) {
      // console.log(payload);
      sessionStorage.setItem("states", JSON.stringify(action.payload.localStates));
      return {
        ...state,
        states: {
          ...state.states,
          states: action.payload.states,
          localStates: action.payload.localStates
        },
      };
    },
    setLocalGovt(state, action) {
      // console.log(payload);
      sessionStorage.setItem("localGovt", JSON.stringify(action.payload.localGovt));
      return {
        ...state,
        localGovt: action.payload.localGovt,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBanks.fulfilled, (state, { payload }) => {
        console.log("fulfilled");
        state.isLoading = false;
        state.banks = payload || [];
      })
      .addCase(getBanks.pending, (state, action) => {
        console.log("pending");
        state.isLoading = true;
      })
      .addCase(getBanks.rejected, (state, { error }) => {
        console.log("rejected");
        state.isLoading = false;
        // notify(error.message, { type: "error" });
      });

      builder
      .addCase(getStates.fulfilled, (state, { payload }) => {
        state.states = payload || {};
      })
      .addCase(getStates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStates.rejected, (state) => {
        state.loading = false;
      })

      builder
      .addCase(getLocalGovt.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.localGovt = payload?.localGovt || [];
      })
      .addCase(getLocalGovt.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocalGovt.rejected, (state) => {
        state.loading = false;
      })
  },
});

export default utilitySlice;
