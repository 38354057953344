import { toast } from "react-toastify";
const defaultOptions = {
  type: "info",
  position: toast.POSITION.TOP_RIGHT
};



export const notify = (message, options = defaultOptions) => {
  const { type, ...rest } = options;

  switch (type) {
    case "success":
    case "info":
    case "warn":
    case "error":
      return toast[type](message, rest);

    default:
      return toast(message, rest);
  }
};
