import { createSlice } from "@reduxjs/toolkit";
import { notify } from "../../utils/toastNotification";
import { getAllUsers, addUser, getUserById } from "../actions/userManagement.action";
import {
  clearStorage,
  getAuthUser,
  getToken,
  // setAuthUser,
  // setToken,
} from "../../utils/storage";

const initialState = {
  accessToken: getToken(),
  userProfile: getAuthUser(),
  isLoading: false,
  allUsers: {
    isLoading: false,
  },
  addUser: {
    isLoading: false,
  },
  userById: {
    isLoading: false,
  },
};

const userManagementSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state, action) {
      state.userProfile = {};
      window.location.href = "/";
      clearStorage();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state, action) => {
      state.allUsers.isLoading = true;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.allUsers = action.payload;
      state.allUsers.isLoading = false;
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.allUsers.isLoading = false;
    });

    builder.addCase(addUser.pending, (state, action) => {
      state.addUser.isLoading = true;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.addUser = action.payload;
      state.addUser.isLoading = false;
      notify("User added successfully", { type: "success" });
      window.location.href = "/crop-gate/user-management/users";
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.addUser.isLoading = false;
    });

    builder.addCase(getUserById.pending, (state, action) => {
      state.userById.isLoading = true;
    });
    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.userById.isLoading = false;
      state.userById = action.payload;
    });
    builder.addCase(getUserById.rejected, (state, action) => {
      state.userById.isLoading = false;
    });
  },
});

export default userManagementSlice;
