import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../configs/api";
// import authAxiosService from "../../services/authAxiosService";
import axiosServices from "../../services/axiosServices";
// import { setToken } from "../../utils/storage";
import { toast } from "react-toastify";

export const getAllUsers = createAsyncThunk(
  "auth/getAllUsers",
  async (thunkAPI) => {
    try {
      const res = await axiosServices.get(`${BASE_URL}/users`);
      // console.log("getAllUsers", res);
      const userData = res?.result?.users;
      return userData;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);

export const addUser = createAsyncThunk(
  "auth/addUser",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServices.post(`${BASE_URL}/users`, payload);
      // console.log(response);
      toast.success("User added successfully");
      window.location.href = "/crop-gate/user-management/users";
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);

export const getUserById = createAsyncThunk(
  "auth/getUserById",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServices.get(`${BASE_URL}/users/${payload}`);
      // console.log(response);
      return response?.result?.user;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);
