import authSlice from "../slices/Auth.slice";
import AppSlice from "../slices/App.slice";
import supplierSlice from "../slices/Supplier.slice";
import collectionSlice from "../slices/Collections.slice";
import userManagementSlice from "../slices/UserManagement.slice";
import utilitySlice from "../slices/Utility.slice";
import cashAdvanceSlice from "../slices/CashAdavance.slice";
import dashboardSlice from "../slices/Dashboard.slice";
import paymentSlice from "../slices/Payment.slice";

export const reducers = {
  Auth: authSlice.reducer,
  App: AppSlice.reducer,
  Supplier: supplierSlice.reducer,
  Collections: collectionSlice.reducer,
  UserManagement: userManagementSlice.reducer,
  Utility: utilitySlice.reducer,
  CashAdvance: cashAdvanceSlice.reducer,
  Dashboard: dashboardSlice.reducer,
  Payment: paymentSlice.reducer
};
